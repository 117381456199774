import {
    STORE_INFO,
    STORE_TOTO,
    STORE_CONFIG,
    STORE_SELECTION,
    SELECT,
    SET_VOTE
} from '../actions';
const initialState = {
    accueil: {},
    user: {},  
    guilds: [],
    selected: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_INFO: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      return {
        ...state,
        accueil: action.home,
      };
    }
    case STORE_TOTO: {
      //console.log("a")
      //console.log(action)
      //console.log(state)
      //console.log(action)
      return {
        ...state,
        user: {logged: 'yes', id: action.user, toto: action.toto},
        guilds: action.guilds,
        //logged: "yes",
        //id: action.user,
        //toto: action.toto,
      };
    }
    case STORE_CONFIG: {
      //console.log(action)
      return {
        ...state,
        guilds: action.guilds,
        //selected: action.guilds[0]
        //logged: "yes",
        //id: action.user,
        //toto: action.toto,
      };
    }
    case SELECT : {
      //console.log(action)
      return {
        ...state,
        selected: action.selected,
        //selected: action.guilds[0]
        //logged: "yes",
        //id: action.user,
        //toto: action.toto,
      };
    }
    case STORE_SELECTION: {
      //console.log("A : " + state.home.guilds)
      //console.log("Selected : "+ action.selected)
      //const guildSelected = state.home.guilds.map((g) => {
      //  if (g.guild_id === action.guild) {
      //    return g;
      //  }
      //  return action.selected;
      //});
      return {
        ...state,
        selected: action.selected,
      };
    }
    case SET_VOTE: {
      return {
        ...state,
        vote: action.val
      };
    }
    default:
      return state;
  }
};

export default reducer;
