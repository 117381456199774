// export const TOGGLE_BURGER = 'TOGGLE_BURGER';
// export const toggleBurger = () => ({
//   type: TOGGLE_BURGER,
// });
// 
// export const CLOSE_BURGER = 'CLOSE_BURGER';
// export const closeBurger = () => ({
//   type: CLOSE_BURGER,
// });
// 
// export const SWITCH_PREFERENCES = 'SWITCH_PREFERENCES';
// export const switchToPreferences = (pageToDisplay) => ({
//   type: SWITCH_PREFERENCES,
//   pageToDisplay,
//   // pageToDisplay = pageToDisplay,
// });
// 
// export const TOGGLE_FILTER = 'TOGGLE_FILTER';
// export const toggleFilter = (category) => ({
//   type: TOGGLE_FILTER,
//   category,
// });
// 
// export const GET_FILTERED_PROMOTIONS = 'GET_FILTERED_PROMOTIONS';
// export const getFilteredPromotions = () => ({
//   type: GET_FILTERED_PROMOTIONS,
// });
// 
// export const CHANGE_VALUE = 'CHANGE_VALUE';
// export const changeValue = (input, value) => ({
//   type: CHANGE_VALUE,
//   input,
//   value,
// });
// 
// export const TOGGLE_ABOUT = 'TOGGLE_ABOUT';
// export const toggleAbout = (name) => ({
//   type: TOGGLE_ABOUT,
//   name,
// });
// 
// export const FETCH_TAGS = 'FETCH_TAGS';
// export const fetchTags = () => ({
//   type: FETCH_TAGS,
// });
// 
// export const SAVE_TAGS = 'SAVE_TAGS';
// export const saveTags = (tagsList) => ({
//   type: SAVE_TAGS,
//   tagsList,
// });
// 
// export const SAVE_PLATFORMS = 'SAVE_PLATFORMS';
// export const savePlatforms = (platformsList) => ({
//   type: SAVE_PLATFORMS,
//   platformsList,
// });
// 
// export const TOGGLE_TAG_TAB = 'TOGGLE_TAG_TAB';
// export const toggleTagTab = (name) => ({
//   type: TOGGLE_TAG_TAB,
//   name,
// });
// 
// export const SAVE_HOME_PROMOTIONS = 'SAVE_HOME_PROMOTIONS';
// export const saveHomePromotions = (promotions, tagsArray) => ({
//   type: SAVE_HOME_PROMOTIONS,
//   promotions,
//   tagsArray,
// });
// 
// export const FETCH_ADVENTURE_GAMES = 'FETCH_ADVENTURE_GAMES';
// export const fetchAdventureGames = (id) => ({
//   type: FETCH_ADVENTURE_GAMES,
//   id,
// });
// 
// export const SAVE_TAGS_FOR_PREFERENCES = 'SAVE_TAGS_FOR_PREFERENCES';
// export const saveTagsForPreferences = (tags) => ({
//   type: SAVE_TAGS_FOR_PREFERENCES,
//   tags,
// });
// 
// export const REFRESH_HOMETAGS = 'REFRESH_HOMETAGS';
// export const refreshHomeTags = (name) => ({
//   type: REFRESH_HOMETAGS,
//   name,
// });
// 
// export const FETCH_RANDOM_GAMES = 'FETCH_RANDOM_GAMES';
// export const fetchRandomGames = () => ({
//   type: FETCH_RANDOM_GAMES,
// });
// 
// export const SAVE_RANDOM_GAMES = 'SAVE_RANDOM_GAMES';
// export const saveRandomGames = (games) => ({
//   type: SAVE_RANDOM_GAMES,
//   games,
// });
// 
// export const SET_FIRST_HOVERED_GAME = 'SET_FIRST_HOVERED_GAME';
// export const setFirstHoveredGame = (games) => ({
//   type: SET_FIRST_HOVERED_GAME,
//   games,
// });
// 

export const GET_INFO = 'GET_INFO';
export const getInfo = () => ({
  type: GET_INFO,
});
export const STORE_INFO = 'STORE_INFO';
export const storeInfo = (info) => ({
  type: STORE_INFO,
  home: info,
});
export const TOTO = 'TOTO';
export const toto = () => ({
  type: TOTO,
});
export const STORE_TOTO = 'STORE_TOTO'
export const storeToto = (token, user, guilds) => ({
  type: STORE_TOTO,
  toto: token,
  user: user,
  guilds: guilds,
});  
export const GET_CONFIG = 'GET_CONFIG';
export const getConfig = () => ({
  type: GET_CONFIG,
});
export const STORE_CONFIG = 'STORE_GONFIG'
export const storeConfig = (config) => ({
  type: STORE_CONFIG,
  guilds: config
});
export const SELECT = 'SELECT';
export const select = (selected) => ({
  type: SELECT,
  selected
});
export const STORE_SELECTION = 'STORE_SELECTION';
export const storeSelection = (selected) => ({
  type: STORE_SELECTION,
  selected
});
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const updateConfig = () => ({
  type: UPDATE_CONFIG,
});
export const SET_VOTE = 'SET_VOTE';
export const setVote = (val) => ({
  type: SET_VOTE,
  val
});

export const GET_BLOG = 'GET_BLOG';
export const getBlog = () => ({
  type: GET_BLOG,
});
export const STORE_BLOG = 'STORE_BLOG';
export const storeBlog = (info) => ({
  type: STORE_BLOG,
  blog: info
});

export const GET_ARTICLE = 'GET_ARTICLE';
export const getArticle = (id) => ({
  type: GET_ARTICLE,
  id
});
export const STORE_ARTICLE = 'STORE_ARTICLE';
export const storeArticle = (info) => ({
  type: STORE_ARTICLE,
  article: info
});