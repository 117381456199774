import {
    STORE_BLOG,
    STORE_ARTICLE,
} from '../actions';
const initialState = {
    blog: [],
    article: []
};

const reducer = (state = initialState, action = {}) => {
    //console.log(action.blog)
    switch (action.type) {
        case STORE_BLOG: {
            //console.log("a")
            //console.log(action)

            //console.log(state)
            return {
                ...state,
                blog: action.blog
            };
        }
        case STORE_ARTICLE: {
            //console.log("a")
            //console.log(action)

            //console.log(state)
            return {
                ...state,
                article: action.article
            };
        }
        default:
            return state;
    }
};

export default reducer;
