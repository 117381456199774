import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Logout = () => {
    
    return (
        <div className="logout">
            <img className="logout-img" src="https://www.aht.li/3693839/aincrad.gif" alt="Aincrad" />
            <h3 className='logout-h1'>You can't, you are now stuck !</h3>
            <p className="logout-p">It's a joke, refresh the page to logout... UwU</p>
        </div>
    );
};

Logout.propTypes = {
    //getInfo: PropTypes.func.isRequired,
};

export default Logout;
