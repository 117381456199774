/* eslint-disable import/no-extraneous-dependencies */
import { Routes, Route, Navigate } from 'react-router-dom'; // BrowserRouter, , Redirect, useLocation
import PropTypes from 'prop-types';
import React, { useEffect } from 'react'; //, { useEffect }
/*
import Search from 'src/containers/Search';
import ProfilePage from 'src/containers/ProfilePage';
import Login from 'src/containers/Login';
import Subscribe from 'src/containers/Login/Subscribe';
import VideoGame from 'src/containers/VideoGame';
import About from 'src/containers/About';
*/
import Home from '../../containers/Home';//'./Home'; //src/containers/Home
//import Blog from '../../containers/Blog';//'./Home'; //src/containers/Home
//import logo from 'src/assets/images/logo_rose_bleu.png';
import Navbar from './Navbar';
import Footer from './Footer';
import Error404 from './404';
import Cgu from './Cgu';
import Team from './Team';
import Login from './Login';
import Logout from './Logout';
import Blog from '../../containers/Blog';
//import Serv from './Home/Serv';
///////////////////////////////////////////////
import Commands from './Commands';
//import Blog from './Blog';
//import MentionLegales from './MentionLegales';

import './style.scss';
//fetchTags, 
//fetchAdventureGames,
//isConnected, 
//closeBurger,
const App = ({
  toto,
  getInfo,
  
}) => {
  //const location = useLocation();
  //useEffect(closeBurger, [location.pathname]);
  //useEffect(() => {
  //  fetchTags(); 
  //  fetchAdventureGames(18);
  //}, []);
  useEffect(toto, [toto]);
  useEffect(getInfo, [getInfo]);
  const handleClick = () => {
    // closeBurger();
  };
  return (
    <div className="app" onClick={handleClick}>
      <Navbar />
      <Routes>
        {/* routes diverses
        <Route exact path="/mentions-legales" element={<Error404 />}/>
        <Route exact path="/contact" element={<Error404 />}/>
        <Route exact path="/statuts" element={<Statuts />}/>
        */}
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/commands" element={<Commands />}/>
        <Route exact path="/cgu" element={<Cgu />}/>
        <Route exact path="/team" element={<Team />}/>
        <Route exact path="/blog" element={<Blog />}/>
        <Route exact path="/login" element={<Login />}/>
        <Route exact path="/logout" element={<Logout />}/>
        <Route exact path="/auth" element={<Navigate to="/"/>}/>
        {/*<Route exact path="/serv" element={<Serv />}/>*/}
        <Route element={<Error404 />}/>
      </Routes> 
      <Footer />
    </div>
  );
};

App.propTypes = {
  getInfo: PropTypes.func.isRequired,
  //getBlog: PropTypes.func.isRequired,
  //fetchTags: PropTypes.func.isRequired,
  //fetchAdventureGames: PropTypes.func.isRequired,
  //isConnected: PropTypes.bool.isRequired,
  //closeBurger: PropTypes.func.isRequired,
};

export default App;
