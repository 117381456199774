import React, { useEffect } from 'react'; // , { useEffect } 
//import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";//, Link

import Articles from './Articles';
import Article from './Article';

//import './style.scss';

const Blog = ({getBlog}) => {
	useEffect(getBlog, [getBlog]);
	const search = useLocation().search;
	const article = new URLSearchParams(search).get('article');
	return (
		<div className="dashboard">
			{
			  article ? <Article/> : <Articles/>
			}
		</div>
	);
};

Blog.propTypes = {
	//getBlog: PropTypes.array.isRequired,
};

export default Blog;