import React from 'react'; // , { useEffect } 
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Team = () => {
    //useEffect(getInfo, [getInfo]);
    //console.log(getInfo)

    //const bidule = useSelector(state => state.home.accueil)
    //console.log(bidule)
    return (
        <div className='team'>
            <img className="team-img" src="https://www.aht.li/3684413/HRa_zW_1.png" alt="predator" />

            <div className='team-users'>
                <h5 className='team-users-p'>This project was born from a team effort !</h5>
                <p className='team-users-p'>697896054607183882 Earlam (Product Owner, Lead Dev Fullstack)</p>
                <p className='team-users-p'>813053611189600307 Whayn (Algorithms lover, Helper)</p>
                <p className='team-users-p'>236207773166075916 Ations (Regex expert, Helper)</p>
                <p className='team-users-p'>623201954839003158 Ecnelis (Tester, Helper)</p>
            </div>

        </div >
    );
};
Team.propTypes = {
    //getInfo: PropTypes.func.isRequired,
};

export default Team;