/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'; // , { useEffect } 
import { useLocation, Link } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'//, useDispatch
//import { Link } from 'react-router-dom';
//import select from '../../../../../actions';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';
const Config = () => { //{ getConfig, updateConfig,}
    const dispatch = useDispatch()
    //useEffect(getInfo, [getInfo]);
    //useEffect(toto, [toto]);
    //useEffect(getConfig, [getConfig]);
    //console.log(getInfo)
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    //const toto = useSelector(state => state.home.user.toto);
    const guilds = useSelector(state => state.home.guilds);
    const guild = guilds.find((g) => g.guild_id === id);
    //let chose = false;
    let vote = guild.vote;
    let mode = guild.mode;
    let prev = guild.prev;
    let prevConfig = guild.prev_config;

    let obj = { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: mode, prev: prev, prev_config: prevConfig, chan: guild.chan }
    dispatch({ type: "STORE_SELECTION", selected: obj });
    //let voteForm = ''
    //let votemodeForm = ''
    //let prevForm = ''
    //let prevconfigForm = ''
    const handleChange = (event) => {
        //dispatch(updateConfig());   
        //updateConfig();
        //console.log(event.target.value)
        //if(event.target.checked) {
        //    console.log("viens d'être checked")
        //} else {
        //    console.log("viens d'être UNchecked")
        //}
        if (event.target.value === 'vote') {
            if (event.target.checked) {
                vote = 'yes'
                dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: 'yes', mode: mode, prev: prev, prev_config: prevConfig, chan: guild.chan } })
            } else {
                vote = 'no'
                dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: 'no', mode: mode, prev: prev, prev_config: prevConfig, chan: guild.chan } })
            };

        } else if (event.target.value === 'mute') {
            mode = 'mute';
            dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: 'mute', prev: prev, prev_config: prevConfig, chan: guild.chan } })
        } else if (event.target.value === 'kick') {
            mode = 'kick';
            dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: 'kick', prev: prev, prev_config: prevConfig, chan: guild.chan } })
        } else if (event.target.value === 'ban') {
            mode = 'ban';
            dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: 'ban', prev: prev, prev_config: prevConfig, chan: guild.chan } })
        } else if (event.target.value === 'prev') {
            if (event.target.checked) {
                prev = 'yes'
                dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: mode, prev: 'yes', prev_config: prevConfig, chan: guild.chan } })
            } else {
                prev = 'no'
                dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: mode, prev: 'no', prev_config: prevConfig, chan: guild.chan } })
            };

        } else if (event.target.value === '100') {
            prevConfig = '100';
            dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: mode, prev: prev, prev_config: '100', chan: guild.chan } })
        } else if (event.target.value === '500') {
            prevConfig = '500';
            dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: mode, prev: prev, prev_config: '500', chan: guild.chan } })
        } else if (event.target.value === '999') {
            prevConfig = '999';
            dispatch({ type: "STORE_SELECTION", selected: { guild_id: guild.guild_id, guild_name: guild.guild_name, guild_icon: guild.guild_icon, vote: vote, mode: mode, prev: prev, prev_config: '999', chan: guild.chan } })
        } else if (event.target.value === 'update') {
            dispatch({ type: 'UPDATE_CONFIG' });
            event.target.style.visibility = 'hidden';
        }

    };
    //const handleUpdate = () => { 
    //    console.log(id)
    //    //dispatch(updateConfig());
    //};

    return (
        <div className="config"> {/*onSubmit={handleSubmit} method="patch" */}
            <img className='config-avatar' alt='guild avatar' src={guild.guild_icon === 'null' ? `https://www.aht.li/3685119/blue.png` : `https://cdn.discordapp.com/icons/${guild.guild_id}/${guild.guild_icon}.png`} />
            <p>{guild.guild_name}</p>
            <h3>When a message is considered suspicious :</h3>

            <div className='config-box'>
                <p>Let the members vote</p>
                <label className='config-vote' value='vote'>
                    {vote === "yes" ? <input onClick={handleChange} value='vote' id='vote' defaultChecked type="checkbox" className='config-vote-toggle' /> : <input onClick={handleChange()} value='vote' id='vote' type="checkbox" className='config-vote-toggle' />}
                </label>
            </div>
            <p className='config-sanction'>Then :</p>
            <div className='config-sanction-options'>
                <label for="mute"></label>
                {mode === "mute" ? <input onClick={handleChange} defaultChecked className='config-sanction-option-input' id='mute' type="radio" value="mute" name="sanction" /> : <input onClick={handleChange} className='config-sanction-option-input' id='mute' type="radio" value="mute" name="sanction" />} Mute
                <label for="kick"></label>
                {mode === "kick" ? <input onClick={handleChange} defaultChecked className='config-sanction-option-input' id='kick' type="radio" value="kick" name="sanction" /> : <input onClick={handleChange} className='config-sanction-option-input' id='kick' type="radio" value="kick" name="sanction" />} Kick
                <label for="ban"></label>
                {mode === "ban" ? <input onClick={handleChange} defaultChecked className='config-sanction-option-input' id='ban' type="radio" value="ban" name="sanction" /> : <input onClick={handleChange} className='config-sanction-option-input' id='ban' type="radio" value="ban" name="sanction" />} Ban
            </div>
            <p>the suspected member...</p>
            <h3>About prevention and warnings :</h3>
            <div className='config-box'>
                <p>Send preventive messages (System channel)</p>
                <label for='prev' className='config-prev' ></label>
                {prev === "yes" ? <input onClick={handleChange} value='prev' id='prev' defaultChecked type="checkbox" className='config-prev-toggle' /> : <input onClick={handleChange} value='prev' id='prev' type="checkbox" className='config-prev-toggle' />}

            </div>
            <p>Every :</p>
            <div className='config-prev-option'>
                <label for="100"></label>
                {prevConfig === "100" ? <input onClick={handleChange} defaultChecked className='config-prev-option-input' id='100' name="prev" type="radio" value="100" /> : <input onClick={handleChange} className='config-prev-option-input' id='100' name="prev" type="radio" value="100" />} 100

                <label for="500"></label>
                {prevConfig === "500" ? <input onClick={handleChange} defaultChecked className='config-prev-option-input' id='500' name="prev" type="radio" value="500" /> : <input onClick={handleChange} className='config-prev-option-input' id='500' name="prev" type="radio" value="500" />} 500

                <label for="999"></label>
                {prevConfig === "999" ? <input onClick={handleChange} defaultChecked className='config-prev-option-input' id='999' name="prev" type="radio" value="999" /> : <input onClick={handleChange} className='config-prev-option-input' id='999' name="prev" type="radio" value="999" />}  999
            </div>
            <p>messages posted on the server...</p>
            {/*
                <label for="dog-names">Choose a dog name:</label>
                    <select name="dog-names" id="dog-names">
                        <option value="rigatoni">Rigatoni</option>
                        <option value="dave">Dave</option>
                        <option value="pumpernickel">Pumpernickel</option>
                        <option value="reeses">Reeses</option>
                    </select>
                </label>
                */}
            <div className='config-buttons'>
                <button className='config-buttons-update' onClick={handleChange} value='update' id='update'>UPDATE</button>
                <Link className='config-buttons-back' to="/">
                    BACK
                </Link>
            </div>

        </div>
    );
};

Config.propTypes = {

};
//getConfig: PropTypes.func.isRequired,
export default Config;