/* eslint-disable max-len */
import React from 'react'; // , {useEffect}

//import { useSelector } from 'react-redux'
//import { select } from '../../../../actions';
//import { getConfig } from '../../../../actions';


import { useLocation } from "react-router-dom";
import Serv from './Serv';

import Config from './Config';

import './style.scss';

const Dashboard = () => {//, getConfig
  //useEffect(getConfig, [getConfig]);
  //useEffect(toto, [toto]);
  //const user = useSelector(state => state.home.user);
  //console.log(user)
  //const guilds = useSelector(state => state.home.guilds);
  //const selected = useSelector(state => state.home.selected);
  
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  //const guilds = useSelector(state => state.home.guilds);
  //const guild = guilds.find((g) => g.guild_id === id);
  //console.log(guild) 


  //const handleClick = (event) => {
  //  if (event.target.parentNode.className === 'dashboard-private-servers-serv') { 
  //    console.log(event.target.id)
  //    //useEffect(select, [select]);
  //    //select(event.target.id);
  //  }    
  //};
  return (
    <div className="dashboard">
        {
          id ? <Config/> : <Serv/>
        }
      </div>
  );
};

Dashboard.propTypes = {

};

export default Dashboard;
