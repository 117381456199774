import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Login = () => {
    let gotit = false
    function handleClick() {

        if (gotit === false) {
            let igotitButtonParent = document.querySelector('.login-igotit');
            //let loginButton = document.createElement("button");
            //igotitButtonParent.innerHTML = '<button class ="login-discord-button">Login</button>';<a class="login-discord-link" href="https://discord.com/oauth2/authorize?client_id=925303810077884416&amp;redirect_uri=https%3A%2F%2Fapi.ripley.link%2Fv1%2Fripley%2Fdashboard%2Fredirect&amp;response_type=code&amp;scope=guilds%20identify">In development</a>
            igotitButtonParent.innerHTML = '<a class="login-discord-link" href="https://discord.com/oauth2/authorize?client_id=925303810077884416&amp;redirect_uri=https%3A%2F%2Fapi.ripley.link%2Fv1%2Fripley%2Fdashboard%2Fredirect&amp;response_type=code&amp;scope=guilds%20identify">LOGIN</a>';
            //igotitButtonParent.appendChild(loginButton);
            gotit = true;
        }
    };
    //console.log(getInfo)
    //useEffect(getInfo, [getInfo]);
    //useEffect(toto, [toto]);
    //useEffect(youGotIt, [youGotIt]);
    //const truc = useSelector(state => state.login);
    //const yougotit = useSelector(state => state.toto)
    //console.log(truc)
    //let harey = [truc.phrase1, truc.phrase2, truc.phrase3, truc.phrase4, truc.phrase5];
    //let compteur = 0;
    //setInterval(function() {
    //  if (compteur === harey.length-1) {
    //    compteur = 0;
    //    document.querySelector('.accueil-slide').innerHTML = `<h3 class='accueil-slide-phrase'>${harey[compteur]}</h3>`;
    //  } else {
    //    compteur++
    //    document.querySelector('.accueil-slide').innerHTML = `<h3 class='accueil-slide-phrase'>${harey[compteur]}</h3>`;
    //  }
    //}, 15*1000);
    return (
        <div className="login">
            <h1 className="login-title">TIP</h1>
            <p className="login-p">If a site/application offers you to authenticate with Discord, always check that the link of the login page begins with this :</p>
            <img className="login-img" src="https://www.aht.li/3684400/good_url.png" alt="discord official domain" />
            <p className="login-p">After Discord gives us it's and your approval, you will be redirected to the dashboard. Read our TOS. We use cookies.</p>
            <div className="login-igotit" onClick={handleClick}>
                <button className="login-igotit-button">
                    I got it
                </button>
            </div>
        </div>
    );
};

Login.propTypes = {
    //getInfo: PropTypes.func.isRequired,
};

export default Login;
