import React from 'react';

import { useSelector } from 'react-redux'
import './style.scss';

const Welcome = () => {
  const truc = useSelector(state => state.home.accueil);
  return (
    <div className='welcome'>
      <img className="welcome-preda" src="https://www.aht.li/3684413/HRa_zW_1.png" alt="predator" />
      <div className='welcome-prez'>
        <h4 className='welcome-prez-p'>Ripley protect now <br />{truc.users} users on {truc.servers} discord servers.</h4>
        <p className='welcome-prez-p'>Fact: <strong>{truc.sus_links_found}</strong> suspicious links have been found...<br />And <strong>{truc.muted_people}</strong> dangerous accounts were quarantined!</p>

        <p className='welcome-prez-p'>Built with love and teamwork, this bot uses aggressive methods and is designed to protect all your discord servers against theft and scam attempts: phishing, malwares, token-grabbers...</p>
        
        <div className='welcome-prez-buttons'>
          
            <a href="https://discord.com/oauth2/authorize?client_id=925303810077884416&permissions=1099511627767&scope=bot" className="welcome-prez-buttons-addbot">
              ADD IT ! <div className="welcome-prez-heart"> P </div>
            </a>
          
        </div>
      </div>
    </div>
  );
};

Welcome.propTypes = {
};

export default Welcome;
