import React, { useEffect } from 'react'; // , { useEffect } 
import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';
import Welcome from './Welcome';
import Dashboard from './Dashboard';
const Home = ({
  getInfo,
  toto,
  getConfig,
  //select,
}) => {
  //console.log(getInfo)
  useEffect(getInfo, [getInfo]);
  useEffect(toto, [toto]);
  //useEffect(select, [select]);
  useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //useEffect(getConfig, [getConfig]);
  //const token = useSelector(state => state.home.user.toto);
  const isLogged = useSelector(state => state.home.user.logged);
  //console.log(isLogged)
    return (
      <div className="dashboard">
        {
          isLogged === 'yes' ? <Dashboard/> : <Welcome/>
        }
      </div>
    );
};

Home.propTypes = {
  //getInfo: PropTypes.func.isRequired,
};

export default Home;
