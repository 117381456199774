/* eslint-disable no-console */
import axios from 'axios';

//import { useLocation } from "react-router-dom"; // pas possible de hook en dehors d'un composant.
import { GET_INFO, storeInfo, TOTO, storeToto, GET_CONFIG, storeConfig, SELECT, UPDATE_CONFIG, storeSelection } from '../actions'; //, UPDATE_CONFIG
import apiBaseURL from './baseURL';
//
//axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
//
const api = axios.create({
  baseURL: apiBaseURL,
});

const homeMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case GET_INFO:
      api.get('/v1/ripley/dashboard/info')
        .then((response) => {
          store.dispatch(storeInfo(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      break;
    case TOTO:
      let guilds = [];
      let cookieToto = document.cookie.split(' ').find(row => row.startsWith('toto='))
      let cookieUser = document.cookie.split(' ').find(row => row.startsWith('user='))
      let cookieGuilds = document.cookie.split(' ');
      
      if (cookieToto && cookieUser) {
        cookieGuilds.forEach(ck => {
          if (ck.includes('-guild=')) {
            guilds.push(ck.split('-guild=')[1].replace(';', ''))
          }
        });
        store.dispatch(storeToto(cookieToto.replace('toto=', '').replace(';', ''), cookieUser.replace('user=', '').replace(';', ''), guilds));
      }
      break;
    case GET_CONFIG:
      let zeToto = document.cookie.split(' ').find(row => row.startsWith('toto='))
      if (!zeToto) return;
      //const { site } = store.getState();
      //const { id } = site.home.guilds.find((guild) => guild === action.name);
      //console.log(id)
      api.get(`/v1/ripley/dashboard/guilds?toto=${zeToto.replace('toto=', '').replace(';', '')}`)
        .then((response) => {
          return store.dispatch(storeConfig(response.data));
        })
        .catch((error) => {
          console.error(error);
        }); 
      break;
      case SELECT:
        //console.log(action)
        //const search = useLocation().search;
        //const id = new URLSearchParams(search).get('id');
        //let {home} = store.getState();
        //let {guild} = home.guilds.find((g) => g.guild_id === id);
        store.dispatch(storeSelection());
      break;
      
      case UPDATE_CONFIG:
        //console.log("home middleware")
        let {home} = store.getState();
        //console.log(home.selected)
        let zeToken = home.user.toto;
        //let zeToken = document.cookie.split(' ').find(row => row.startsWith('toto='))
        //if (!zeToken) return;
        //console.log(zeToken)
        //console.log('pouet')
        //const params = [req.body.vote, req.body.voteoption, req.body.prev, req.body.prevoption, req.params.id];
        axios(`https://api.ripley.link/v1/ripley/dashboard/guild?toto=${zeToken.replace('toto=', '').replace(';', '')}`, {
          method: 'patch', 
          headers: {},
          data: {id: `${home.selected.guild_id}`, vote: `${home.selected.vote}`, voteoption: `${home.selected.mode}`, prev: `${home.selected.prev}`, prevoption: `${home.selected.prev_config}`}
        })

        //api.patch(`/v1/ripley/dashboard/guild?toto=${zeToken.replace('toto=', '').replace(';', '')}`, {id: home.selected.guild_id, vote: home.selected.vote, voteoption: home.selected.mode, prev: home.selected.prev, prevoption: home.selected.prev_config})
        .then((response) => {
          //console.log(response);
          api.get(`/v1/ripley/dashboard/guilds?toto=${zeToken.replace('toto=', '').replace(';', '')}`)
          .then((resp) => {
            return store.dispatch(storeConfig(resp.data));
          })
          .catch((error) => {
            console.error(error);
          }); 
        })
        .catch((error) => {
          console.error(error);
        });
        
        //store.dispatch(storeSelection(guild.guild_id));
      break;
    default:
      next(action);
  }
};

export default homeMiddleware;
