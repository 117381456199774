/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'; // , { useEffect } 
//import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';
const Commands = () => { //{ getCommands, }
    //useEffect(getCommands, []);
    //console.log(getInfo)
    return (
        <div className="commands">
            <img className='commands-img' alt="ripley" src='https://www.aht.li/3684390/ezgifcom-gif-maker.gif'></img>
            <div className='commands-items'>
                <p className='commands-items-command'>!h</p>
                <p className='commands-items-info'>display commands and bot information</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!s</p>
                <p className='commands-items-info'>display the server configuration</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!m +</p>
                <p className='commands-items-info'>mute newcomers for 24 hours</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!m -</p>
                <p className='commands-items-info'>don't mute newcomers for 24 hours</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!m @member</p>
                <p className='commands-items-info'>mute a member for 28 days</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!k @member</p>
                <p className='commands-items-info'>kick a member</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!b @member</p>
                <p className='commands-items-info'>ban a member</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!v</p>
                <p className='commands-items-info'>automate a voice channel, cool way to reduce them</p>
            </div>
            
        </div>
    );
};

Commands.propTypes = {

};
//getCommands: PropTypes.func.isRequired,
export default Commands;
