import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

//import { useSelector } from 'react-redux'
import './style.scss';
import './phone.scss';

const Footer = () => {
  //const truc = useSelector(state => state.home.accueil)
  //console.log(truc)
  return (
    <footer className="footer">
      {/*
      <div className="footer-rezosozial">

        <a href={truc.tweeter} className="footer-rezosozial-links">
          <img className="footer-rezosozial-links-item" src="https://www.aht.li/3645923/tweeter.png" alt="logo facebook" />
        </a>
        <a href={truc.youtube} className="footer-rezosozial-links">
          <img className="footer-rezosozial-links-item" src="https://www.aht.li/3645925/yt.png" alt="logo facebook" />
        </a>
        <a href="https://discord.gg/GBrrtJNVMm" className="footer-rezosozial-links">
          <img className="footer-rezosozial-links-item" src="https://www.aht.li/3645924/discord.png" alt="logo facebook" />
        </a>
        <a href={truc.facebook} className="footer-rezosozial-links">
          <img className="footer-rezosozial-links-item" src="https://www.aht.li/3645922/fb.png" alt="logo facebook" />
        </a>
      </div>
      */}
      {/*
      <div className="footer-links">
        <Link to="/contact" className="footer-links-item">
          Contactez-nous
        </Link>
        <Link to="/mentions-legales" className="footer-links-item">
          Mentions légales
        </Link>
        <Link to="/statuts" className="footer-links-item">
          Statuts
        </Link>
      </div>
      */}
      <div className="footer">
        <div className='footer-items'>
        <a href="https://discord.gg/hCTU84az84" className="footer-items-link">
          Support
        </a>
        <Link to="/cgu" className="footer-items-link">
          Terms
        </Link>
        <Link to="/team" className="footer-items-link">
          Team
        </Link>
        {/*<img className="footer-items-img" alt="predator" src='https://www.aht.li/3684413/HRa_zW_1.png'></img>*/}
        </div>
      </div>

    </footer>
  )

};

Footer.propTypes = {

};

export default Footer;
