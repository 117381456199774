import React from 'react'; // , { useEffect } 
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from "react-router-dom";//, Link
//import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import {Helmet} from "react-helmet";


import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Article = () => {

  const dispatch = useDispatch()
  const search = useLocation().search;
  const art = new URLSearchParams(search).get('article');
  console.log(art)
  let bidule = useSelector(state => state.blog.article);
  console.log(bidule.art_id);
  if (bidule.length === 0 || parseInt(art) !== parseInt(bidule.id)) {
    dispatch({ type: "GET_ARTICLE", selected: art });
  }
  const gifs = [
    'https://www.aht.li/3711067/a.gif',
    'https://www.aht.li/3711068/b.gif',
    'https://www.aht.li/3711069/c.gif',
    'https://www.aht.li/3711065/d.gif',
    'https://www.aht.li/3711070/e.gif',
    'https://www.aht.li/3711066/f.gif',
    'https://www.aht.li/3711071/g.gif',
  ]
  //setTimeout(() => {
  //    //console.log(bidule)
  //    console.log(bidule.content)
  //}, 10000);
  
  //document.querySelector('meta[name="description"]').content = `${bidule.content.replace(/␥/g, '"')}`;
  //document.querySelector('meta[name="title"]').content = `${bidule.title}`;
  /*console.log(bidule)
  const el = document.querySelector('meta[name="description"]');
  bidule.length>0 && el.setAttribute('content',`${bidule.content.replace(/␥/g, '"')}`)
  document.title = bidule.title;
  */
  //document.querySelector('meta[name="title"]').setAttribute('content',`${bidule.title}`)
  return (
    <div className='blog'>
      <Helmet>
        <title>{bidule.title}</title>
        <meta name="description" content={bidule.length !== 0 ? `${bidule.content.replace(/␥/g, '"')}`: ""} />
      </Helmet>
      <div className='blog-article'>
        <h2 className='blog-article-titre' >
          <Markdown>{bidule.title ? `${bidule.title}` : 'LOADING...'}</Markdown>
        </h2>
        <img src={bidule.art_img !== null ? `${bidule.art_img}` : gifs[Math.floor(Math.random() * gifs.length)]} alt="Ripley on the alien movies" className='blog-img'></img>
        <p>{bidule.last_update ? `Last edit : ${new Date(parseInt(bidule.last_update)).toDateString()}` : ''}</p>
        <div className='blog-article-text'>
          <Markdown>{bidule.content ? `${bidule.content.replace(/␥/g, '"')}` : '...'}</Markdown>
        </div>

      </div>
      <div className='blog-link'>
        <Link className='blog-link-allposts' to="/blog">
          See more posts
        </Link>
      </div>


    </div>
  );
};

Article.propTypes = {
  //getBlog: PropTypes.array.isRequired,
};

export default Article;