/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import React from 'react';
// import PropTypes from 'prop-types';
/*import Menu from 'src/containers/Menu';
import Form from 'src/containers/Form';
import Logo from './Logo';*/
import './style.scss';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux'
//import './phone-style.scss'; 
//import logo from '../../../assets/logo_med_transpa.png'
const Navbar = () => {
  
  //const toto = useSelector(state => state.home.toto)
  const isLogged = useSelector(state => state.home.user.logged);
  //console.log(isLogged)
  return (
    <header className="header">
      <div className="header-links">
        <Link to="/" className="header-links-item">
          Dashboard
        </Link>
        <Link to="/commands" className="header-links-item">
          Commands
        </Link>
        <Link to="/blog" className="header-links-item">
          Blog
        </Link>
        {
          isLogged !== 'yes' ? <Link to="/login" className="header-links-item">Login</Link> : <Link to="/logout" className="header-links-item">Logout</Link>
        }
      </div>
    </header>
  );
};

Navbar.propTypes = {

};

export default Navbar;
