/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'; // , { useEffect } 
//import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import {
  Link
  } from 'react-router-dom';
//import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';
const Serv = () => { //{ getServ, }
  const user = useSelector(state => state.home.user);
  //console.log(user)
  //const guilds = useSelector(state => state.home.guilds);
  const guilds = useSelector(state => state.home.guilds);
  //console.log(guilds)
  return (
    <div className='dashboard-private'>
      <img className='dashboard-private-user' alt='user avatar' src={`https://cdn.discordapp.com/avatars/${user.id.split('-')[0]}/${user.id.split('-')[1]}.png`} />
      <p className='dashboard-private-choose'>Choose a server</p>
      <div className='dashboard-private-servers'>
        {
          guilds[0] && guilds.map((g) => (
            <Link to={`?id=${g.guild_id}`} id={g.guild_id} className='dashboard-private-servers-serv' key={g.guild_id}>
              <img id={g.guild_id} className="dashboard-private-servers-serv-img" alt='guild icon' src={g.guild_icon === null ? `https://www.aht.li/3685119/blue.png` : `https://cdn.discordapp.com/icons/${g.guild_id}/${g.guild_icon}.png`} />
            </Link>
          ))
        }
      </div>
      <p className='dashboard-private-p'>or</p>
      <a className='dashboard-private-addbot' href="https://discord.com/oauth2/authorize?client_id=925303810077884416&permissions=8&scope=bot">
        add the bot
      </a>
    </div>
  );
};

Serv.propTypes = {

};
//getServ: PropTypes.func.isRequired,
export default Serv;
