import React from 'react'; // , { useEffect } 
//import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types';
/*import Promotions from 'src/containers/Promotions';
import Filter from 'src/containers/Filter';
import Recommendations from 'src/containers/Recommendations';
*/
import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Cgu = () => {
    //useEffect(getInfo, [getInfo]);
    //console.log(getInfo)

    //const bidule = useSelector(state => state.home.accueil)
    //console.log(bidule)
    return (
        <div className='cgu'>
            {/*<img className="divers-img" src="https://www.aht.li/3684413/HRa_zW_1.png" alt="predator" />*/}

            <div className='cgu-a'>
                <div>
                    <h1 className='cgu-titles'>About the bot and the site</h1>
                    <p className='cgu-articles'>This is a translation. In case of dispute, the French version will prevail: In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of June 21, 2004 on Confidence in the digital economy, known as LCEN, Users of the site https://machin- chose.fr these legal notices: The connection and navigation on the site ripley.link by the User implies full and unreserved acceptance of these legal notices and our conditions of use. These are accessible on the site under the heading “CGU”.</p>
                </div>
                <div>
                    <h1 className='cgu-titles'>ARTICLE 1: The publisher</h1>
                    <p className='cgu-articles'>The edition and the direction of the publication of the site https://ripley.link is ensured by Jimmy Gaubert, Web and mobile web developer, domiciled in Brittany, whose telephone number is undefined lulz, and email address ripley@truc-bidule.fr</p></div>
                <div><h1 className='cgu-titles'>ARTICLE 2: The host</h1>
                    <p className='cgu-articles'>The host of the site https://ripley.link is the associative host NiHost, whose head office is located at 19 rue de la République 93260 Les Lilas.</p>
                </div>
            </div>
            <div className='cgu-b'>
                <div>
                    <h1 className='cgu-titles'>ARTICLE 3: Access to the site</h1>
                    <p className='cgu-articles'>The site is accessible from any location, 7 days a week, 24 hours a day, except in cases of force majeure, scheduled or unscheduled interruption and possibly resulting from a need for maintenance. In the event of modification, interruption or suspension of the services, the site https://ripley.link cannot be held responsible.</p>
                </div>
                <div>
                    <h1 className='cgu-titles'>ARTICLE 4: Data collection</h1>
                    <p className='cgu-articles'>The site is exempt from declaration to the Commission Nationale Informatique et Libertés (CNIL) insofar as it does not collect any data concerning users. Only the bot does it, not the site. The data we collect is the content of discord messages considered suspicious or potentially dangerous</p>
                </div>
                <div>
                    <h1 className='cgu-titles'>SECTION 5: Cookies</h1>
                    <p className='cgu-articles'>The User is informed that during his visits to the site, a cookie may be automatically installed on his navigation software. By browsing the site, he accepts them. A cookie is an element that does not identify the User but is used to record information relating to his browsing on the website. Its lifespan of 5 minutes. The User may deactivate this cookie via the parameters appearing in his browser software.</p>
                </div>
            </div>
            <div className='cgu-c'>
                <div>
                    <h1 className='cgu-titles'>ARTICLE 6: Intellectual property</h1>
                    <p className='cgu-articles'>Any use, reproduction, distribution, marketing, modification of all or part of the site https://ripley.link, without the authorization of the Publisher is prohibited and may result in legal action and prosecution as provided for in particular by the Code. intellectual property and the Civil Code.</p>
                </div>
                <div>
                    <h1 className='cgu-titles'>ARTICLE 7: TOS, Banning and Reports</h1>
                    <p className='cgu-articles'>This contract is valid for an indefinite period. The beginning of the use of the services of the site and/or the Ripley bot mark the application of the contract with regard to the User. As for the phishing links the bot collects, we'd love to hear your thoughts. Devs working on this project reserve the right not to respond to all unban requests. This contract is subject to French law. The absence of amicable resolution of cases of dispute between the parties implies recourse to the competent French courts to settle the dispute...</p>
                    <p className='cgu-articles'>( ͡° ͜ʖ ͡°)</p>
                </div>

            </div >
        </div>
    );
};
Cgu.propTypes = {
    //getInfo: PropTypes.func.isRequired,
};
export default Cgu;