import React from 'react'; // , { useEffect } 
import { useSelector } from 'react-redux';//, useDispatch
import { useLocation } from "react-router-dom";//, Link
import { Link } from "react-router-dom";
//import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';



import './style.scss';
//import './phone-style.scss';
//import './tablet-style.scss';

const Articles = () => {
	//const dispatch = useDispatch()

	const search = useLocation().search;
	const art = new URLSearchParams(search).get('article');
	console.log(art)
	//const handleChange = (x) => {
	//	return dispatch({ type: "GET_ARTICLE", selected: x });
	//}
	const truc = useSelector(state => state.blog.blog);
	//console.log(truc)
	const gifs = [
		'https://www.aht.li/3711067/a.gif',
		'https://www.aht.li/3711071/g.gif',
		'https://www.aht.li/3711069/c.gif',
		'https://www.aht.li/3711065/d.gif',
		'https://www.aht.li/3711070/e.gif',
		'https://www.aht.li/3711066/f.gif',
		'https://www.aht.li/3711068/b.gif',
	]
	let compteur = 0;
	
	
	return (
		<div className='blog'>
			{truc.map((data, id) => {
				if (compteur > gifs.length-2) {compteur=0};
				//console.log(compteur)
				compteur++
				return <div className='blog-article' key={id}>
					<Link className='blog-article' to={`/blog?article=${data.id}`}> {/* onClick={handleChange(data.id)} */}
						<h2><Markdown>{data.title}</Markdown></h2>
					</Link>
					<img src={data.art_img !== null ? `${data.art_img}`: gifs[compteur]} alt="" className='blog-img'></img>
					<p>Last edit : {new Date(parseInt(data.last_update)).toDateString()}</p>
					<div className='blog-article-text'>
						<Markdown>{data.content}</Markdown>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</div>
				</div>
			})}
		</div>
	);
};

Articles.propTypes = {
	//getBlog: PropTypes.array.isRequired,
};

export default Articles;